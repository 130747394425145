.rsvp-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 10px 5px;
}

.rsvp-form div{
  padding: 5px;
}

.rsvp-form p{
  font-size: medium;
  margin: 0;
}