@import 'home';
@import 'CountDown';
@import 'rsvp';
@import 'PracticalInfo';
@import 'Photo';


.App-body{
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #fffefe;
  overflow: hidden;
}
body{
  background-color: #282c34;
}