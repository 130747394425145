.upload__image-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100vh;
}

.buttons-container {
  display: flex;
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 2px 4px; /* Top and bottom padding of 10px, left and right padding of 24px */
  margin: 4px;
  border: none; /* No border */
  cursor: pointer; /* Change mouse cursor to pointer */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for background color change on hover */
}

.upload-button:hover {
  background-color: #3a853d; /* Darker shade of green on hover */
}

.image-item {
  position: relative;
  flex: none;
  height: 18vh;
  width: 14vh;
  cursor: pointer; /* Indicates clickable area */
  padding: 0.6vh; /* Add padding */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.76); /* Add a shadow to act as a frame */
  margin: 0.3vh; /* Ensure there's space between items */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease; /* Smooth transition for filter */
}

.image-item:hover img {
  filter: blur(1px) brightness(90%);
}

.image-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent grey overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-item:hover::before {
  opacity: 1;
}

.image-item__btn-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; /* Set width to 50% of its parent */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Hide "X" by default */
  transition: opacity 0.3s ease;
  cursor: pointer;
  color: #dfdfdf; /* Ensure "X" is white for better visibility */
  font-size: 32px; /* Adjust if necessary to fit the new width */
  font-weight: bold; /* Make it bold */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Add text shadow for contrast */
  text-align: center; /* Ensure the "X" is centered within the wrapper */
}

.image-item:hover .image-item__btn-wrapper {
  opacity: 1; /* Show "X" on hover */
}